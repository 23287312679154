import * as React from 'react'
import {
  Box,
  useBoxStyles,
  BoxProps,
  usePseudoBoxStyles,
} from '@walltowall/calico'
import { undefIfEmpty } from '@walltowall/helpers'
import clsx from 'clsx'

import { Heading } from './Heading'
import { Link } from './Link'
import { Text } from './Text'
import { Inline } from './Inline'

export type PostCardProps = {
  href: string
  authorName?: string
  authorHref?: string
  authorColor?: string
  date: string
  title: string
  excerpt?: string
  tags?: ({ name?: string; url?: string } | undefined)[]
  className?: string
} & BoxProps

export const PostCard = ({
  href,
  authorName,
  authorHref,
  authorColor,
  date,
  title,
  excerpt,
  tags,
  ...props
}: PostCardProps) => {
  // const aspectRatioClassName = useBoxStyles({ height: 'full' })
  const linkClassName = clsx(
    useBoxStyles({
      transitionProperty: 'color',
      transitionDuration: 'normal',
      transitionTimingFunction: 'easeOut',
    }),
    usePseudoBoxStyles({ color: 'gray80' }, 'focus'),
    usePseudoBoxStyles({ color: 'gray80' }, 'hover'),
  )

  return (
    <Box
      {...props}
      styles={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: ['16rem', '16rem', '22rem'],
        ...props.styles,
      }}
    >
      <Box
        styles={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'gray10',
          color: 'gray50',
          padding: [7, 7, 8],
          flexGrow: 1,
        }}
      >
        <Box
          styles={{
            display: 'grid',
            gridAutoFlow: 'column',
            gap: 3,
            justifyContent: 'start',
            marginBottom: [4, 4, 5],
          }}
        >
          {authorName && (
            <Text
              variant="mono-caps-12"
              styles={{ fontWeight: 'bold' }}
              style={{ color: authorColor }}
            >
              <Link href={authorHref!}>{authorName}</Link>
            </Text>
          )}
          <Text variant="mono-caps-12" styles={{ color: 'gray40' }}>
            {date}
          </Text>
        </Box>
        {title && (
          <Heading
            level={2}
            variant="sansCondB"
            styles={{ color: 'white', marginBottom: [4, 5, 6] }}
          >
            <Link href={href} className={linkClassName}>
              {title}
            </Link>
          </Heading>
        )}
        {excerpt && (
          <Text
            as="p"
            variant="serif-16-20"
            styles={{ flexGrow: 1, marginBottom: [10, 10, 14] }}
          >
            {excerpt}
          </Text>
        )}
        {tags && undefIfEmpty(tags) && (
          <Box>
            <Inline space={3}>
              {tags.map(
                (tag) =>
                  tag &&
                  tag.url && (
                    <Text
                      key={tag.name}
                      variant="mono-caps-12"
                      styles={{ color: 'gray40' }}
                    >
                      <Link href={tag.url}>
                        <Box as="span" styles={{ color: 'gray30' }}>
                          #
                        </Box>
                        {tag.name}
                      </Link>
                    </Text>
                  ),
              )}
            </Inline>
          </Box>
        )}
      </Box>
    </Box>
  )
}
