import * as React from 'react'

import { Columns } from './Columns'

type PostCardListProps = {
  children: React.ReactNode
}

export const PostCardList = ({ children }: PostCardListProps) => {
  const childCount = React.Children.count(children)

  return (
    <Columns
      variant="list"
      space={[4, 5, 6]}
      count={[1, 2, 3, 4]}
      align={[
        'start',
        childCount < 2 ? 'center' : 'start',
        childCount < 3 ? 'center' : 'start',
        childCount < 4 ? 'center' : 'start',
      ]}
    >
      {React.Children.map(
        children,
        (child) =>
          React.isValidElement(child) &&
          React.cloneElement(child, { styles: { height: 'full' } }),
      )}
    </Columns>
  )
}
