import * as React from 'react'
import { graphql } from 'gatsby'
import { Box } from '@walltowall/calico'

import { AuthorTemplateQuery } from '../graphqlTypes'

import { AuthorStamp } from '../components/AuthorStamp'
import { BoundedBox } from '../components/BoundedBox'
import { Layout } from '../components/Layout'
import { PostCard } from '../components/PostCard'
import { PostCardList } from '../components/PostCardList'

export type AuthorTemplateProps = {
  data: AuthorTemplateQuery
}

export const AuthorTemplate = ({ data }: AuthorTemplateProps) => {
  const author = data.author
  const authorImageFluid = author?.image?.childImageSharp?.fluid
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout>
      <BoundedBox styles={{ paddingBottom: [20, 25, 30] }}>
        {author && (
          <Box styles={{ marginBottom: [8, 10, 12] }}>
            <AuthorStamp
              variant="large"
              imageFluid={authorImageFluid}
              name={author?.name}
              title={author?.title}
            />
          </Box>
        )}
        <Box
          styles={{
            width: 'full',
            maxWidth: 'xlarge',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <PostCardList>
            {posts.map(
              (post) =>
                post && (
                  <PostCard
                    key={post.fields?.url}
                    href={post?.fields?.url!}
                    authorName={post?.frontmatter?.author?.shortName}
                    authorHref={post?.frontmatter?.author?.fields?.url}
                    authorColor={post.frontmatter?.authorColor}
                    date={post?.frontmatter?.date}
                    title={post?.frontmatter?.title!}
                    excerpt={post?.frontmatter?.excerpt}
                    tags={post?.fields?.tags}
                  />
                ),
            )}
          </PostCardList>
        </Box>
      </BoundedBox>
    </Layout>
  )
}

export default AuthorTemplate

export const query = graphql`
  query AuthorTemplate($id: String!) {
    author(id: { eq: $id }) {
      email
      image {
        childImageSharp {
          fluid(maxWidth: 160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      name
      title
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { author: { id: { eq: $id } } }
        fileAbsolutePath: { regex: "//index.md$/" }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        ...Post
      }
    }
  }
`
